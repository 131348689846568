<template>
  <div>
    <a @click="showEditModal" v-if="canUpdateAdminUser">
      <va-icon
        name="fa fa-pencil-square-o"
        :size="21"
        class="action-icon pointer"
      />
    </a>

    <a @click="showDeleteModal = true" v-if="canDeleteAdminUser">
      <va-icon name="fa fa-trash-o" :size="21" class="action-icon pointer" />
      <va-modal
        v-model="showDeleteModal"
        size="small"
        title="Delete user"
        :okText="$t('modal.confirm')"
        @ok="deleteUser"
      >
        Are you sure you want to delete user?
      </va-modal>
    </a>

    <va-modal
      v-model="showModal"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="updateAdminUser"
      @cancel="resetForm"
    >
      <div class="row">
        <div class="flex md12">
          <va-input v-model="newUser.name" label="Name" placeholder="Name" />
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <va-input
            v-model="newUser.email"
            label="Email"
            type="email"
            placeholder="someone@domain"
          />
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <p class="display-6">Roles</p>
          <tag-input
            v-model="newUser.roles"
            textKey="name"
            placeholder="Roles"
            :ajaxFunction="loadRolesInDropDown"
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { isAllowed } from '../../../mixins/utils';
import {
  deleteAdminUser,
  updateAdminUser,
} from '../../../apollo/api/admin-users';
import { NEW_ADMIN_USER } from '../../../constants/defaultValues';
import { fetchRolesForDropdown } from '../../../apollo/api/roles';
import TagInput from '../../../components/TagInput';

export default {
  name: 'admin-user-actions',
  components: {
    TagInput,
  },
  props: { user: Object },
  data() {
    return {
      newUser: NEW_ADMIN_USER,
      showModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters(['ACCESS_CODES', 'RESOURCE_CODES']),
    canReadAdminUser() {
      return isAllowed([this.RESOURCE_CODES.USER([this.ACCESS_CODES.READ])]);
    },
    canUpdateAdminUser() {
      return isAllowed([
        this.RESOURCE_CODES.USER([
          this.ACCESS_CODES.READ,
          this.ACCESS_CODES.UPDATE,
        ]),
      ]);
    },
    canDeleteAdminUser() {
      return isAllowed([this.RESOURCE_CODES.USER([this.ACCESS_CODES.DELETE])]);
    },
  },
  methods: {
    ...mapMutations(['setLoading', 'setBackgroundLoading']),
    showEditModal() {
      this.resetForm();
      this.showModal = true;
    },
    resetForm() {
      this.newUser = { ...this.user };
    },
    async loadRolesInDropDown(search = '') {
      this.setBackgroundLoading(true);
      const { rolesList } = await fetchRolesForDropdown(search);
      this.setBackgroundLoading(true);
      return rolesList.roles.map(p => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    async updateAdminUser() {
      this.setLoading(true);
      try {
        this.newUser.roles = this.newUser.roles.map(r => r.id);
        await updateAdminUser({
          ...this.newUser,
          id: this.user.id,
        });

        this.showToast('User updated successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async deleteUser() {
      this.setLoading(true);
      try {
        await deleteAdminUser(this.user.id);

        this.showToast('User deleted successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>
