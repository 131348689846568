<template>
  <va-card>
    <div v-if="canListAdminUsers">
      <div class="row align--center">
        <add-user-modal />
        <div class="flex xs12 md3 offset--md3">
          <va-input
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @keydown="searchUser"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>
      <loader v-if="isLoading" />
      <data-table
        :fields="fields"
        :data="users"
        :per-page="pagination.limit"
        :total-pages="pagination.pages"
        :currentPage="pagination.currentPage"
        :api-mode="true"
        @page-selected="loadAdminUsers"
        v-else
      >
        <template slot="roles" slot-scope="props">
          <tag-input
            v-model="props.rowData.roles"
            :textKey="'name'"
            :placeholder="''"
            :disabled="true"
            v-if="props.rowData.roles.length"
          />
          <p v-else>No roles</p>
        </template>
        <template slot="createdAt" slot-scope="props">
          {{ props.rowData.createdAt | validDateFormat }}
        </template>
        <template slot="actions" slot-scope="props">
          <user-actions :user="props.rowData" />
        </template>
      </data-table>
    </div>
    <access-denied v-else />
  </va-card>
</template>

<script>
import { fetchAdminUsers } from '../../../apollo/api/admin-users';
import { mapGetters, mapMutations } from 'vuex';
import { isAllowed } from '../../../mixins/utils';
import { profilePicFilter, validDateFormat } from '../../../mixins/filters';
import { ADMIN_USERS_TABLE_FIELD } from '../../../constants/tableFields';
import DataTable from '../../../components/DataTable';
import AccessDenied from '../../../components/AccessDenied.vue';
import AddUserModal from './AddUserModal.vue';
import TagInput from '../../../components/TagInput.vue';
import Loader from '../../../components/Loader';
import UserActions from './UserActions.vue';

export default {
  components: {
    DataTable,
    Loader,
    TagInput,
    AccessDenied,
    UserActions,
    AddUserModal,
  },
  data() {
    return {
      term: '',
      perPage: '20',
      perPageOptions: ['20', '30', '40', '50'],
      roleTags: [],
      showAddModal: false,
      showDeleteModal: false,
      showEditUserModal: false,
      users: [],
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
    };
  },
  filters: {
    profilePicFilter,
    validDateFormat,
  },
  computed: {
    ...mapGetters(['isLoading', 'ACCESS_CODES', 'RESOURCE_CODES']),
    fields() {
      return ADMIN_USERS_TABLE_FIELD;
    },
    canListAdminUsers() {
      return isAllowed([this.RESOURCE_CODES.USER([this.ACCESS_CODES.LIST])]);
    },
  },
  watch: {
    perPage: function (newVal) {
      this.pagination.limit = parseInt(newVal);
      this.loadAdminUsers();
    },
    term: function (newVal, oldVal) {
      if (oldVal.length > 0 && newVal.length === 0) {
        this.loadAdminUsers();
      }
    },
  },
  async mounted() {
    await this.loadAdminUsers(1);
  },
  methods: {
    ...mapMutations(['setLoading']),
    getRoles(roles) {
      return roles.map((r) => ({ id: r.name, text: r.name }));
    },
    async loadAdminUsers(page = 1) {
      this.setLoading(true);
      try {
        const { usersList } = await fetchAdminUsers(
          this.term,
          this.pagination.limit,
          page
        );

        this.users = usersList.users;
        this.pagination = {
          ...this.pagination,
          currentPage: usersList.currentPage,
          pages: usersList.pages,
          total: usersList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
    async searchUser(e) {
      if (e.key === 'Enter') {
        await this.loadAdminUsers(1);
      }
    },
  },
};
</script>

<style lang="scss">
.action-icon {
  margin: 5px;
  color: 'black';
}
</style>
