<template v-if="canAddAdminUser">
  <div class="flex xs12 md3">
    <va-button @click="showModal = true">Add user</va-button>
    <va-modal
      v-model="showModal"
      title="Create user"
      :size="'medium'"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="submitNewUser"
      @cancel="resetForm"
    >
      <div class="row">
        <div class="flex md6">
          <va-input v-model="user.name" label="Name" placeholder="Name" />
        </div>
      </div>
      <div class="row">
        <div class="flex md12">
          <va-input
            v-model="user.email"
            label="Email"
            type="email"
            placeholder="someone@domain"
          />
        </div>
      </div>
      <div class="row">
        <va-input
          v-model="user.password"
          label="Password"
          type="password"
          placeholder="User password"
        />
        <va-input
          v-model="confirmPassword"
          label="Confirm Password"
          type="password"
          placeholder="User password"
        />
      </div>
      <div class="row">
        <div class="flex md12">
          <tag-input
            v-model="user.roles"
            textKey="name"
            placeholder="Roles"
            :ajaxFunction="loadRolesInDropDown"
          />
        </div>
      </div>
    </va-modal>
  </div>
</template>

<script>
import { NEW_ADMIN_USER } from '../../../constants/defaultValues';
import { mapMutations, mapGetters } from 'vuex';
import { fetchRolesForDropdown } from '../../../apollo/api/roles';
import { createAdminUser } from '../../../apollo/api/admin-users';
import { isAllowed } from '../../../mixins/utils';
import TagInput from '../../../components/TagInput';

export default {
  name: 'add-user-modal',
  components: {
    TagInput,
  },
  data() {
    return {
      confirmPassword: '',
      user: NEW_ADMIN_USER,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['ACCESS_CODES', 'RESOURCE_CODES']),
    canAddAdminUser() {
      return isAllowed([this.RESOURCE_CODES.USER([this.ACCESS_CODES.CREATE])]);
    },
  },
  async created() {
    await this.loadRolesInDropDown('');
  },
  watch: {
    showModal() {
      this.resetForm();
    },
  },
  methods: {
    ...mapMutations(['setBackgroundLoading', 'setLoading']),
    resetForm() {
      this.user = NEW_ADMIN_USER;
    },
    async loadRolesInDropDown(search = '') {
      this.setBackgroundLoading(true);
      const { rolesList } = await fetchRolesForDropdown(search);
      this.setBackgroundLoading(true);
      return rolesList.roles.map(p => {
        return {
          id: p.id,
          text: p.name,
        };
      });
    },
    async submitNewUser() {
      this.setLoading(true);
      try {
        if (this.confirmPassword !== this.user.password) {
          // TODO: show user he/she is wrong
          alert("Passwords don't match");
          this.resetForm();
          return;
        }
        this.user.roles = this.user.roles.map(r => r.id);
        await createAdminUser(this.user);

        this.showToast('User created successfully', {
          position: 'top-right',
          duration: 800,
          fullWidth: false,
        });
        this.setLoading(false);
        window.location.reload();
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>
