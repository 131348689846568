import { QUERIES, MUTATIONS } from '../schemas/admin-user';
import { apolloClient } from '../index';

export const fetchAdminUsers = async(search = '', limit = 50, page = 1) => {
  const { data } = await apolloClient.query({
    query: QUERIES.FETCH_ADMIN_USERS,
    variables: { search, limit, page },
  });

  return data;
};

export const updateAdminUser = async({ id, name, email, roles }) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.UPDATE_USER,
    variables: { id, name, email, roles },
  });

  return data;
};

export const createAdminUser = async({ name, email, password, roles }) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.CREATE_USER,
    variables: { name, email, password, roles },
  });

  return data;
};

export const changeSelfPassword = async(id, oldPassword, newPassword) => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.CHANGE_SELF_PASSWORD,
    variables: { id, oldPassword, newPassword },
  });

  return data;
};

export const deleteAdminUser = async userId => {
  const { data } = await apolloClient.mutate({
    mutation: MUTATIONS.DELETE_USER,
    variables: { userId },
  });
  return data;
};
