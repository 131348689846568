import gql from 'graphql-tag';

export const QUERIES = {
  FETCH_ADMIN_USERS: gql`
    query FETCH_ADMIN_USERS($search: String, $limit: Int, $page: Int) {
      usersList(search: $search, limit: $limit, page: $page) {
        users {
          id
          name
          email
          roles {
            id
            name
          }
          createdAt
        }
        currentPage
        pages
        total
      }
    }
  `,
};

export const MUTATIONS = {
  CHANGE_SELF_PASSWORD: gql`
    mutation CHANGE_SELF_PASSWORD(
      $id: ID!
      $oldPassword: String
      $newPassword: String
    ) {
      changePassword(
        userId: $id
        oldPassword: $oldPassword
        newPassword: $newPassword
      ) {
        response
      }
    }
  `,
  CREATE_USER: gql`
    mutation CREATE_ADMIN_USER(
      $name: String
      $email: String
      $password: String
      $roles: [ID]
    ) {
      createUser(
        userData: {
          name: $name
          email: $email
          password: $password
          roles: $roles
        }
      ) {
        user {
          id
          name
          email
          roles {
            name
          }
        }
      }
    }
  `,
  UPDATE_USER: gql`
    mutation UPDATE_USER(
      $id: ID!
      $name: String
      $email: String
      $roles: [ID]
    ) {
      updateUser(
        userData: { id: $id, name: $name, email: $email, roles: $roles }
      ) {
        user {
          id
          name
          email
          roles {
            id
            name
          }
        }
      }
    }
  `,
  DELETE_USER: gql`
    mutation DELETE_ADMIN_USER($userId: ID!) {
      deleteUser(userId: $userId) {
        response
      }
    }
  `,
};
